import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import ResizeContext from "./context/ResizeContext";
import { AuthProvider } from "./context/AuthContext";
// import { AlarmProvider } from "./context/AlarmContext";
import routes from "./router/routes";
import { RouterProvider } from "react-router-dom";
import ThemeContext from "./context/ThemeContext";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      {/* <AlarmProvider> */}
        <ThemeContext>
          <ResizeContext>
            <RouterProvider router={routes} />
          </ResizeContext>
        </ThemeContext>
      {/* </AlarmProvider>  */}
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
