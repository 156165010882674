import React, { useRef, useState, useEffect,useContext } from "react";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { NavLink } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button'
import ButtonFeature from "../features/Button"
import "primeicons/primeicons.css";

import { BiSolidUserDetail } from "react-icons/bi";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../api/api.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Spinner from 'react-bootstrap/Spinner';
import { AiFillDelete } from "react-icons/ai";
import { AuthContext } from "../context/AuthContext";

function User() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);
  const { user } = useContext(AuthContext);
  const [listUser, setListUser] = useState([]);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dt = useRef(null);

  const detailsInfo = (rowData) => {
    const styleBtn = {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "1em",
      color: "black",
    };
    return (
      <NavLink to={`/utilisateurs/${rowData.id}`} style={styleBtn}>
        <BiSolidUserDetail fontSize="1.5em" />
      </NavLink>
    );
  };
  const footer = `${listUser ? listUser.length : 0} Personnels.`;

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    const exportExcel = () => {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(user);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        saveAsExcelFile(excelBuffer, "files");
      });
    };

    const saveAsExcelFile = (buffer, fileName) => {
      import("file-saver").then((module) => {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      });
    };
    return (
      <div className="conteneur-search">
        <div className="btn-upload-file">
          <Button
            type="button"
            label="Excel format"
            icon="pi pi-file-excel"
            style={{
              border: "none",
              color: "white",
              margin: "1em 0",
            }}
            onClick={exportExcel}
            data-pr-tooltip="XLS"
          />
        </div>

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();
  const removeUser = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await instance.delete(`users/${userId}`);
      console.log("RESPONSE",response)
      if (response.data.status === false) {
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false)
    
      }else{
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setUserId("");
        setShowModal(false);
        setIsLoading(false)
        setListUser(listUser.filter(item=>item.id !== userId))
        console.log("UTILISATEUR",listUser)
      }
     
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: error.data.message,
        icon: "info",
        showConfirmButton: true,
      });
    }
  };

  const action = ({ id }) => {
    const deleteUser = ()=>{
      setUserId(id);
      console.log(userId);
      setShowModal(true);
      console.log(showModal)
    }
    return (
      <button
      className="btn bg-danger text-light fw-bold"
        onClick={deleteUser}
      >
        <AiFillDelete /> Supprimer
      </button>
    );
  };

  
  
  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await instance.get("users");
        console.log(response.data.data);
        setListUser(response.data.data.filter(item=>item.role.label !== "Commercial" && item.role.label !=="Prospect" && item.role.label !== "Client"));
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);

  if(!listUser.length) return (
    <div className="conteneur-page">
       <h1>Personnels</h1>
       <div className="text-center fw-bold">
        Pas de donnée.
       </div>

    </div>
  )

  return (
    <div className="conteneur-page">
      {showModal ? (
        <div className="conteneur-modal">
          <form onSubmit={removeUser}>
            <h5 className="m-2">Voulez-vous supprimer ce personnel ?</h5>
               <ButtonFeature titre="Confirmer" styleBtn={styleBtn} isLoading={isLoading} />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setUserId("");
                setShowModal(false);
              }}
            >
              Annuler
            </button>
          </form>
        </div>
      ) : null}

      <h1>Personnels</h1>
     
    
      <TabView>
      <TabPanel>
          <DataTable
            ref={dt}
            value={listUser}
            footer={footer}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={10}
            filters={filters}
            globalFilterFields={["firstname", "lastname", "email", "role.label"]}
            header={header}
          >
            <Column field="firstname" header="Nom"></Column>
            <Column field="lastname" header="Prénoms"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="role.label" header="Role"></Column>
            <Column field="id" header="Détail" body={detailsInfo}></Column>
            <Column field="id" header="Action" body={action}></Column>
          </DataTable>
        </TabPanel>
        </TabView>
    </div>
  );
}

export default User;
