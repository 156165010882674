import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { NavLink } from "react-router-dom";
// import { NavLink, useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import ButtonFeature from "../../features/Button"
// import { BiSolidUserDetail } from "react-icons/bi";
import "primeicons/primeicons.css";

import { AiFillEye } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";

function ListeClients() {
  // const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getListUsers = async () => {
      setIsLoading(true);
      try {
        const response = await instance.get("Clients");
        console.log("CLIENTS", response);
        setClients(response.data.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getListUsers();
  }, []);

  return (
    <div className="conteneur-page">
      <h1>Liste des clients</h1>
      <ClientsDatatable
        items={clients}
        isLoading={isLoading}
        // action={souscrire}
      />
    </div>
  );
}
export function ClientsDatatable({ items }) {
  const dt = useRef(null);

  const footer = `${items ? items.length : 0} clients.`;

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    
  
    return (
      <div className="conteneur-search">
        

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();
  const detailsInfo = (rowData) => {
    const styleBtn = {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "1em",
      color: "black",
    };
    return (
      <NavLink to={`/clients/${rowData.id}`} style={styleBtn}>
        <AiFillEye fontSize="1.5em" />
      </NavLink>
    );
  };


  return (
    <div>
        <TabView>
          <TabPanel>
            <DataTable
              ref={dt}
              value={items}
              footer={footer}
              tableStyle={{ minWidth: "50rem" }}
              paginator
              rows={10}
              filters={filters}
              globalFilterFields={["lastname", "firstname", "phone", "email"]}
              header={header}
            >
              <Column
                field="nom"
                style={{ textAlign: "center" }}
                header="Nom"
               
              ></Column>
              <Column
                field="prenom"
                style={{ textAlign: "center" }}
                header="Prénoms"
                
              ></Column>
             
              <Column
                field="phone"
                style={{ textAlign: "center" }}
                header="Téléphone"
              ></Column>
              <Column
                field="email"
                style={{ textAlign: "center" }}
                header="Email"
              ></Column>

              <Column
                field="id"
                style={{ textAlign: "center" }}
                header="Groupe"
                body={detailsInfo}
              ></Column>
             
              
            </DataTable>
          </TabPanel>
        </TabView>
    </div>
  );
}

export default ListeClients;
