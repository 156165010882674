import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import instance from "../../api/api";
// import Badge from "react-bootstrap/Badge";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";

function DetailClient() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailClient, setDetailClient] = useState(null);

  useEffect(() => {
    const getDetailClient = async () => {
      try {
        const response = await instance.get(`users/${id}`);
        console.log(response);

        setDetailClient(response.data.data);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };
    getDetailClient();
  }, [id]);

  return (
    <>
      {
        detailClient && 
       
        <div className="detail-student">
        <div className="back-user">
          <span
            className="back-home"
            onClick={() => {
              navigate("/clients");
            }}
          >
            <IoChevronBackCircleOutline />
          </span>
          <h5>
            <span className="text-primary">Client</span>/{" "}
            <span className="text-primary">Détail</span> /{" "}
            {detailClient.prenom} {detailClient.nom}
          </h5>
        </div>
        <section>
          


          <div>
            <div>
              <code>Nom</code> : {detailClient.nom}
            </div>
            <div>
              <code>Prénoms</code> : {detailClient.prenom}
            </div>
            <div>
              <code>Email</code> : {detailClient.email}
            </div>
            <div>
              <code>Contact</code> :{detailClient.phone}
            </div>
            <div>
              <code>Groupes</code> :
              <div>
              {detailClient.groupes.map(item=>(
                <li>{item.libelle}</li>
              ))}
              </div>
              
            </div>
          </div>
        </section>
      
      </div>
      }
    </> 
  );
}

export default DetailClient;
