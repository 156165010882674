import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ShowDashBoard from "../compoment/Dashboard/ShowDashBoard";
import Accueil from "../pages/Accueil";
import User from "../pages/User";
import Profil from "../pages/Profil";

import Page404 from "../pages/Page404";
import DetailUser from "../pages/DetailUser";
import { RequireAuth } from "../compoment/RequireAuth";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import ListeService from "../pages/services/ListeService";
import DetailServices from "../pages/services/DetailServices"; 
import CreateService from "../pages/services/CreateService";

import GroupeCreate from "../pages/groupe/GroupeCreate";
import GroupeDetail from '../pages/groupe/GroupeDetail';
import GroupeList from '../pages/groupe/GroupeList';

import CreateGroupe from "../pages/groupe/CreateGroupe";
import CouponsDetail from '../pages/coupons/CouponsDetail';
import CouponsList from '../pages/coupons/CouponsList';


import PromotionsCreate from "../pages/promotions/PromotionsCreate";
import PromotionsDetail from '../pages/promotions/PromotionsDetail';
import PromotionsList from '../pages/promotions/PromotionsList';
 

import ListeClients from "../pages/clients/ListeClients";
import DetailClient from "../pages/clients/DetailClient";
import Statistique from "../pages/statistique/Statistique";
import Messages from "../pages/Messagerie/Messages"
import CreateMessage from "../pages/Messagerie/CreateMessage"
import ReservationDetail from "../pages/Messagerie/ReservationDetail";
import ListePersonnel from "../pages/Personnel/ListePersonnel"
import DetailPersonnels from "../pages/Personnel/DetailPersonnels";

import Refused from "../pages/Refused"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Page404 />,
  },

  {
    path: "mot-de-passe-oublie",
    element: <ForgetPassword />,
  },
  {
    path: "refused",
    element: <Refused />,
  },
  {
    path: "changer-password/:email/:token",
    element: <ResetPassword />,
  },

  {
    path: "",
    element: (
      <RequireAuth>
        <ShowDashBoard />
      </RequireAuth>
    ),
    children: [
      {
        path: "/tableau-bord",
        element: (
          <RequireAuth>
            {" "}
            <Accueil />{" "}
          </RequireAuth>
        ),
      },
      { 
        path: "/utilisateurs",
        element: (
          <RequireAuth>
            {" "}
            <User />{" "}
          </RequireAuth>
        ),
      },
   

      {
        path: "/utilisateurs/:id",
        element: (
          <RequireAuth>
            {" "}
            <DetailUser />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/services",
        element: (
          <RequireAuth>
            {" "}
            <ListeService />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/personnels",
        element: (
          <RequireAuth>
            {" "}
            <ListePersonnel />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/creer-client",
        element: (
          <RequireAuth>
            {" "}
            <CreateService />{" "}
          </RequireAuth>
        ),
      },
      {
        path: "/services/:id",
        element: (
          <RequireAuth>
            {" "}
            <DetailServices />{" "}
          </RequireAuth>
        ),
        },


        {
          path: "/groupe",
          element: (
            <RequireAuth>
              {" "}
              <GroupeList />{" "}  
            </RequireAuth>
          ),
        },
        {
          path: "/creer-conditions",
          element: (
            <RequireAuth>
              {" "}
              <GroupeCreate />{" "}
            </RequireAuth>
          ),
        },
        {
          path: "/groupe/:id",
          element: (
            <RequireAuth>
              {" "}
              <GroupeDetail />{" "}
            </RequireAuth>
          ),
          },

          {
            path: "/coupons",
            element: (
              <RequireAuth>
                {" "}
                <CouponsList />{" "}  
              </RequireAuth>
            ),
          },
          {
            path: "/Mesreservations/:id",
            element: (
              <RequireAuth>
                {" "}
                <DetailPersonnels />{" "}  
              </RequireAuth>
            ),
          },
          {
            path: "/creer-groupe",
            element: (
              <RequireAuth>
                {" "}
                <CreateGroupe />{" "}
              </RequireAuth>
            ),
          },
          {
            path: "/coupons/:id",
            element: (
              <RequireAuth>
                {" "}
                <CouponsDetail />{" "}
              </RequireAuth>
            ),
            },
            {
              path: "/event",
              element: (
                <RequireAuth>
                  {" "}
                  <PromotionsList />{" "}  
                </RequireAuth>
              ),
            },
            {
              path: "/creer-event",
              element: (
                <RequireAuth>
                  {" "}
                  <PromotionsCreate />{" "}
                </RequireAuth>
              ),
            },
            {
              path: "/event/:id",
              element: (
                <RequireAuth>
                  {" "}
                  <PromotionsDetail />{" "}
                </RequireAuth>
              ),
              },
              {
                path: "/messages",
              element: (
                <RequireAuth>
                  {" "}
                  <Messages />{" "}
                </RequireAuth>
              ),
              },
              {
                path: "/creer-message",
                element: (
                <RequireAuth>
                  {" "}
                  <CreateMessage />{" "}
                </RequireAuth>
              ),
              },
              {
                path: "/reservations/:id",
              element: (
                <RequireAuth>
                  {" "}
                  <ReservationDetail />{" "}
                </RequireAuth>
              ),
              },
      {
        path: "/clients/:id",
        element: (
          <RequireAuth>
            {" "}
            <DetailClient />{" "}
          </RequireAuth>
        ),
      },

      {
        path: "/clients",
        element: (
          <RequireAuth>
            {" "}
            <ListeClients />{" "}
          </RequireAuth>
        ),
      },




      
      {
        path: "/statistique",
        element: (
          <RequireAuth>
            {" "}
            <Statistique />{" "}
          </RequireAuth>
        ),
      },











 

  

      {
        path: "profil",
        element: <Profil />,
      },
    ],
  },
]);

export default router;
