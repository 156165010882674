import React from 'react'

function Refused() {
  return (
    <div>
      <h1 className='text-center'>Vous n'etes pas autorisé.</h1>
    </div>
  )
}

export default Refused
