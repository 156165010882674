import React, { useState } from "react";
import Button from "../features/Button";
import Swal from "sweetalert2";
import instance from "../api/api.js";
import withReactContent from "sweetalert2-react-content";
import Input from "../features/Input";
import { useParams} from "react-router-dom";


function ResetPassword() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);
  const {token,email} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data,setData] = useState({password:"",Cpassword:""})

  const sendDataForResetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = instance.post("passreset",{
        email:email,
        token:token,
        password:data.password
      });
      if (!response.data.status) {
        setIsLoading(false);
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
      }
      MySwal.fire({
        title: response.data.message,
        icon: "info",
        showConfirmButton: true,
      });
    } catch (error) {
      MySwal.fire({
        title: error.data.message,
        icon: "success",
 showConfirmButton: true,
      });
    }
  };
 
  return (
    <div className="reset-password">
      <form onSubmit={sendDataForResetPassword}>
        <h1>Créer un nouveau mot de passe</h1>
        <Input
          active={false}
          label="Nouveau mot de passe"
          typeInput="password"
          nameInput="password"
          dataValue={data.password}
          handleChangePassword={(e)=>{
            setData({ ...data, password: e.target.value })
          }}
        />
        <Input
          active={false}
          label="Confirmation du mot de passe"
          typeInput="password"
          nameInput="password"
          dataValue={data.Cpassword}
          handleChangePassword={(e)=>{
            setData({ ...data, Cpassword: e.target.value })
          }}
        />
        <span>Les deux mots de passes doivent correspondre.</span>
        <Button
          titre="Changer le mot de passe"
          styleBtn={styleBtn}
          isLoading={isLoading}
        />
      </form>
    </div>
  );
}

export default ResetPassword;
