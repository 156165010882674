import React, {useState} from "react";
import instance from "../../api/api";
import InputCreate from "../../features/InputCreate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Button from "../../features/Button";


function GroupeCreate() {
    const styleBtn = {
        padding: ".5em",
        borderRadius: "5px",
        fontWeight: "bold",
        cursor: "pointer",
        color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
        marginTop: "1em",
        border: "none",
      };
      const MySwal = withReactContent(Swal);
      
      const [data, setData] = useState(  {
        nombre:"",
        periode:"",
      });
      const [isLoading, setIsLoading] = useState(false);
      const createCondition = async (e) => {
        e.preventDefault();
        console.log("RESUME DATA",data)
        setIsLoading(true);
        try {
        
          const response = await instance.post("conditions",data);
         
          console.log(response)
          if (response.data.status === false) {
            let msgError = []
            for (const property in response.data.message) {
              msgError = [...msgError,response.data.message[property]]
            }
            MySwal.fire({
              title: msgError,
              icon: "info",
              showConfirmButton: true,
            });
            setIsLoading(false);
          }else{
            setIsLoading(false);
            setData( {
              path:[],
              description:"",
              duree:"",
              tarif:"",
              libelle:"",
            });
            MySwal.fire({
              title: "Enregistrement reussi.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
          let msgError = []
          for (const property in error.response.data.message) {
          
            msgError = [...msgError,error.response.data.message[property]]
          }
          MySwal.fire({
            title: msgError,
            icon: "info",
            showConfirmButton: true,
          });
          setIsLoading(false);
        }
      };
      
      return (
        <div className="create-user">
            <h1>Créer une condition</h1>
          <form onSubmit={createCondition}>
            <InputCreate
              label="Nombre de personnes"
              typeInput="text"
              nameInput="name"
              active={true}
              getValue={((e)=>{
                setData({ ...data, nombre: e.target.value });
              })}
              dataValue={data.nombre}
            />
            
            <InputCreate
              label="Période pour la condition"
              typeInput="date"
              nameInput="periode"
              active={true}
              getValue={((e)=>{
                setData({ ...data, periode: e.target.value });
              })}
              dataValue={data.periode}
            
            />
            <div>
              <Button titre="Envoyer" styleBtn={styleBtn} isLoading={isLoading} />
            </div>
          </form>
        </div>
      );
}

export default GroupeCreate
