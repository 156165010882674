import React, { useEffect, useState, useContext } from "react";
import { Chart } from "primereact/chart";
import { CiFilter } from "react-icons/ci";
import { AuthContext } from "../../context/AuthContext";
import instance from "../../api/api.js";

function Statistique() {
  const { user } = useContext(AuthContext);

  return (
    <div className="conteneur-page">
      <div className="card mt-3 w-100">
        {(user.role.label === "Assistante Comptable" ||
          user.role.label === "Superviseur") && <StatistiqueAgence />}
        {(user.role.label === "Responsable Réseaux" ||
          user.role.label === "Gestionnaire" ||
          user.role.label === "Responsable Agence") && (
          <StatistiqueResponsable />
        )}
      </div>
    </div>
  );
}

function StatistiqueAgence() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chooseGenreStat, setChooseGenreStat] = useState("");
  const [chooseFiltreByCat, setChooseFiltreByCat] = useState("");
  const [chooseCommercial, setChooseCommercial] = useState("");

  useEffect(() => {
    const data = {
      labels: [
        "Janv",
        "febr",
        "Mars",
        "Avr",
        "Mai",
        "juin",
        "Juillet",
        "Aout",
        "septemb",
        "Octobre",
        "novembre",
        "decembre",
      ],
      datasets: [
        {
          label: "Stock",
          data: [540, 325, 702, 620, 840, 240, 540, 140, 940, 740, 640, 340],
          backgroundColor: ["rgba(153, 102, 255, 0.2)"],
          borderColor: ["rgb(75, 192, 192)"],
          borderWidth: 1,
        },
        {
          label: "Vendus",
          data: [540, 325, 702, 620, 840, 240, 540, 140, 940, 740, 640, 340],
          backgroundColor: ["rgba(255, 159, 64, 0.2)"],
          borderColor: ["rgb(153, 102, 255)"],
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);
 console.log("chooseCommercial",chooseCommercial)
  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-3">
        <h1>Statistique</h1>
        <select
          name=""
          id=""
          className="p-1"
          onChange={(e) => setChooseFiltreByCat(e.target.value)}
        >
          <option value="" selected disabled>
            Séléctionne
          </option>
          <option value="commercial">Filtre par commercial</option>
          <option value="global">Filtre global</option>
        </select>
      </div>

      <div className="d-flex justify-content-start align-items-center">
        {chooseFiltreByCat === "commercial" && (
          <select
            name=""
            id=""
            className="p-1 mx-2"
            onChange={(e) => setChooseCommercial(e.target.value)}
          >
            <option value="" selected disabled>
              Séléctionne
            </option>
            <option value="commercial 1">commercial 1</option>
            <option value="commercial 2">commercial 2</option>
          </select>
        )}
        {(chooseFiltreByCat === "global" ||
          chooseFiltreByCat === "commercial") && (
          <select
            name=""
            id=""
            className="p-1 mx-2"
            onChange={(e) => setChooseGenreStat(e.target.value)}
          >
            <option value="" selected disabled>
              Séléctionne
            </option>
            <option value="annee">annee</option>
            <option value="mois">mois</option>
            <option value="semaine">semaine</option>
          </select>
        )}

        {chooseGenreStat === "annee" && (
          <input className="p-3 w-25" type="number" />
        )}
        {chooseGenreStat === "mois" && (
          <input className="p-3 w-25" type="month" />
        )}
        {chooseGenreStat === "semaine" && (
          <input className="p-3 w-25" type="week" />
        )}
        <button className="btn bg-warning fw-bold mx-2">
          <CiFilter />
        </button>
      </div>

      <Chart
        type="bar"
        className="w-100"
        style={{
          padding: "1em",
        }}
        data={chartData}
        options={chartOptions}
      />
    </>
  );
}

function StatistiqueResponsable() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chooseGenreStat, setChooseGenreStat] = useState("");
  const [chooseFiltreByCat, setChooseFiltreByCat] = useState("");
  const [chooseAgence, setChooseAgence] = useState("");
  const [dataProspectFiltre, setDataProspectFiltre] = useState([]);
  const [dataClientFiltre, setDataClientFiltre] = useState([]);
  const [dataSouscriptionFiltre, setDataSouscriptionFiltre] = useState([]);
  const [liste, setListe] = useState([]);
  const [dataStockRecu,setDataStockRecu] = useState([]);
  const [dataStockVendu,setDataStockVendu] = useState([]);
  const { user } = useContext(AuthContext);
  const [dataFilter, setDataFilter] = useState({
    annee: "",
    mois: "",
    semaine: "",
  });
  const [dataWeekFilter, setDataWeekFilter] = useState({
    date_debut: "",
    date_fin: "",
  });
  const [typeDeStatistic,setTypeDeStatistic] = useState("")
  const [months, setMonths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const roleUser = user.role.label
  useEffect(() => {
    const data = {
      labels: months,
      datasets:  roleUser !== "Responsable Agence" ?  [
        {
          label: "Prospects",
          data: dataProspectFiltre,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 1,
        },
        {
          label: "Clients",
          data: dataClientFiltre,
          backgroundColor: "pink",
          borderColor: "pink",
          borderWidth: 1,
        },
        {
          label: "Souscription",
          data: dataSouscriptionFiltre,
          backgroundColor: "orange",
          borderColor: "orange",
          borderWidth: 1,
        },
      ]:[
        {
          label: "Stock recu",
          data: dataStockRecu,
          backgroundColor: "blue",
          borderColor: "blue",
          borderWidth: 1,
        },
        {
          label: "Produits vendu",
          data: dataStockVendu,
          backgroundColor: "pink",
          borderColor: "pink",
          borderWidth: 1,
        },
      ],
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [months, dataSouscriptionFiltre, dataClientFiltre,
     dataProspectFiltre,dataStockRecu,dataStockVendu,roleUser]);

  const getDayOfWeek = (e) => {
    console.log(e.target.value);
    let year = parseInt(e.target.value.slice(0, 4), 10);
    let week = parseInt(e.target.value.slice(6), 10);
    let day = 1 + (week - 1) * 7;
    let dayOffset = new Date(year, 0, 1).getDay();
    dayOffset--;
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        new Date(year, 0, day - dayOffset + i).toISOString().substring(0, 10)
      );
    }
    const dateWeekDebut = days[0];
    const dateWeekFin = days[days.length - 1];
    console.log("debutweek", dateWeekDebut);
    console.log("finweek", dateWeekFin);
    setDataWeekFilter({
      ...dataWeekFilter,
      date_debut: dateWeekDebut,
      date_fin: dateWeekFin,
    });
    // setDataWeekFilter({...dataWeekFilter,date_fin:dateWeekFin})
  };
  const filterWithoutRevenu = async ()=>{
    setIsLoading(true);
    const filtreByAgence = chooseFiltreByCat === "agences"  ? chooseAgence : "";
    console.log("filtreByAgence", filtreByAgence);
    let data = {
      annee: dataFilter.annee ? dataFilter.annee : "",
      mois: dataFilter.mois ? dataFilter.mois : "",
      semaine: dataFilter.semaine ? 1 : "",
      date_debut: dataFilter.semaine ? dataWeekFilter.date_debut : "",
      date_fin: dataFilter.semaine ? dataWeekFilter.date_fin : "",
      agence_id: filtreByAgence,
    };
    const routeProspect = filtreByAgence ? "filtreProspectAgence": "nombreGlobaleProspectGR";
    const routeClient = filtreByAgence ? "filtreClientAgence" : "nombreGlobaleClientGR";
    const routeSouscription = filtreByAgence ? "filtreSouscriptionAgence" : "nombreGlobaleSouscriptionGR";
    console.log("routeProspect", routeProspect);
    console.log("routeClient", routeClient);
    console.log("routeSouscription", routeSouscription);
    try {
      const response = await instance.post(routeProspect, data);
      const responseClient = await instance.post(routeClient, data);
      const responseSouscription = await instance.post(routeSouscription, data);
      console.log("nombreGlobaleProspectGR", response);
      console.log("nombreGlobaleClientGR", responseClient);
      console.log("nombreGlobaleSouscriptionGR", responseSouscription);

      const month = [];
      const value = [];
      const souscription = [];

      response.data.data.forEach((element) => {
        month.push(element.mois ? element.mois : element.jour);
        value.push(element.valeur);
      });
      const client = [];
      responseClient.data.data.forEach((element) => {
        client.push(element.valeur);
      });
      responseSouscription.data.data.forEach((element) => {
        souscription.push(element.valeur);
      });
      console.log("month", month);
      setMonths(month);
      setDataProspectFiltre(value);
      setDataClientFiltre(client);
      setDataSouscriptionFiltre(souscription);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  const filtreWithData = async (e) => {
    e.preventDefault();
   
  
    if(typeDeStatistic !== "autre"){
      filterRevenu()
    }else{
      filterWithoutRevenu()
   }
   
  };


  const filterRevenu = async()=>{
    console.log("revenu")
    setIsLoading(true);
 
    let data = {
      annee: dataFilter.annee ? dataFilter.annee : "",
      agence_id: chooseAgence,
    };
    try{
      const responseRevenu = await instance.post("filtreChiffreAffaireAgence", data);
      console.log("responseStockVendu", responseRevenu);
      const revenu = [];
      const  month = [];  
      responseRevenu.data.data.forEach((element) => {
        month.push(element.mois ? element.mois : element.jour);
        revenu.push(element.valeur);
      });
      }catch(error){
        console.log(error);
        setIsLoading(false);
      }
  }
  const filterProspectClientSouscription = async (e)=>{
    console.log("pas de revenu")
    setIsLoading(true);
    let data = {
      annee: dataFilter.annee ? dataFilter.annee : "",
      mois: dataFilter.mois ? dataFilter.mois : "",
      semaine: dataFilter.semaine ? 1 : "",
      date_debut: dataFilter.semaine ? dataWeekFilter.date_debut : "",
      date_fin: dataFilter.semaine ? dataWeekFilter.date_fin : "",
      commercial_id: chooseAgence,
    };
    e.preventDefault();
    try {
      const responseStock = await instance.post("nombreGlobaleStockRecuCom", data);
      const responseStockVendu = await instance.post("filtreStockVenduCommercial", data);
     
      console.log("responseStock", responseStock);
      console.log("responseStockVendu", responseStockVendu);
    

      const stock = [];
      const stockvendu = [];
      
     const  month = [];
      responseStock.data.data.forEach((element) => {
        month.push(element.mois ? element.mois : element.jour);
        stock.push(element.valeur);
      });
  
      responseStockVendu.data.data.forEach((element) => {
        stockvendu.push(element.valeur);
      });
      
      console.log("month", month);
      setMonths(month);
      setDataStockRecu(stock);
      setDataStockVendu(stockvendu);
      
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  const filtreWithDataForResponseAgence = () => {
    console.log("typeDeStatistic",typeDeStatistic)
 console.log("chooseAgence",chooseAgence)
 filterProspectClientSouscription()

  };

  const route =  user.role.label !== "Responsable Agence" ? "agences":"Vendeurs";
  useEffect(() => {

    const getList = async () => {
      
      try {
        const response = await instance.get(route);
        console.log("LISTE AGENCE OR COMMERCIAUX", response.data);
        setListe(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
getList()
  },[route]);

  const resetValue = () => {
    setDataFilter({
      annee: "",
      mois: "",
      semaine: "",
    });
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-3">
        <h1>Statistique</h1>
        {
          user.role.label !== "Responsable Agence" && 
          <select
          name=""
          id=""
          className="p-1"
          onChange={(e) => setChooseFiltreByCat(e.target.value)}
        >
          <option value="" selected disabled>
            Séléctionne
          </option>
          {user.role.label !== "Responsable Agence" && (
            <>
              <option value="agences">Filtre par agences</option>
              <option value="global">Filtre global</option>
            </>
          )}
        </select>
        }
        
      </div>

      <div className="d-flex justify-content-start align-items-center">
       {
        chooseFiltreByCat === "agences" &&
        <select
            name=""
            id=""
            className="p-1 mx-2"
            onChange={(e) => setChooseAgence(e.target.value)}
          >
            <option value="" selected disabled>
              Séléctionne
            </option>
            {liste.map((item) => (
                <option key={item.id} value={item.id}>
                {item.nom}
              </option> 
              ))
              }
             
          </select>
       }
       {
        user.role.label === "Responsable Agence" &&  
        <select
        name=""
        id=""
        className="p-1 mx-2"
        onChange={(e) => setChooseAgence(e.target.value)}
      >
        <option value="" selected disabled>
          Séléctionne
        </option>
        {liste.map((item) => (
            <option key={item.id} value={item.id}>
            {item.firstname} {item.lastname}
          </option> 
          ))
          }
      </select>
        
       }
          {
             (chooseAgence && chooseFiltreByCat === "agences") &&  
             <select
            name=""
            id="" 
            className="p-1 mx-2"
            onChange={(e) => {
              
              setTypeDeStatistic(e.target.value);
            }}
          >
            <option value="" selected disabled>
              Séléctionne
            </option>
            <option value="Revenu">Revenu</option>
            <option value="autre">Autre</option>
          </select>
          }
          

          <select
            name=""
            id=""
            className="p-1 mx-2"
            onChange={(e) => {
              resetValue();
              setChooseGenreStat(e.target.value);
            }}
          >
            <option value="" selected disabled>
              Séléctionne
            </option>
            <option value="annee">annee</option>
            {
              (typeDeStatistic === 'autre' || chooseFiltreByCat)  && 
              <>
            <option value="mois">mois</option>
            <option value="semaine">semaine</option>
              </>
            }
           
          </select>


        {chooseGenreStat === "annee" && (
          <input
            className="p-3 w-25"
            value={dataFilter.annee}
            onChange={(e) =>
              setDataFilter({ ...dataFilter, annee: e.target.value })
            }
            type="number"
          />
        )}
        {chooseGenreStat === "mois" && (
          <input
            className="p-3 w-25"
            value={dataFilter.mois}
            onChange={(e) =>
              setDataFilter({ ...dataFilter, mois: e.target.value })
            }
            type="month"
          />
        )}
        {chooseGenreStat === "semaine" && (
          <input
            className="p-3 w-25"
            value={dataFilter.semaine}
            onChange={(e) => {
              setDataFilter({ ...dataFilter, semaine: e.target.value });
              getDayOfWeek(e);
            }}
            type="week"
          />
        )}
        {chooseGenreStat && (
          <button
            className="btn bg-warning fw-bold mx-2"
            onClick={user.role.label !== "Responsable Agence" ? filtreWithData:filtreWithDataForResponseAgence}
          >
            <CiFilter />
          </button>
        )}
      </div>
      {isLoading ? (
        <div className="fw-bold text-center">Loading...</div>
      ) : (
        <Chart type="bar" data={chartData} options={chartOptions} />
      )}
    </>
  );
}

export default Statistique;
