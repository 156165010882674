import React, { useEffect, useState,useRef } from "react";
import { useParams, useNavigate,NavLink } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import instance from "../../api/api";
// import Input from "../../features/Input";
// import Button from "../../features/Button";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";
// import Table from "react-bootstrap/Table";
// import { Tabs } from "antd-mobile";
// import {Help} from "../../utils"
// import { Dialog } from 'primereact/dialog';

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";

import { AiFillEye } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";


function DetailPersonnels() {

  const navigate = useNavigate();
  const { id } = useParams();
  const [detailPersonnel, setDetailPersonnel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getReservationDetail = async () => {
      try {
        const response = await instance.get(`/users/${id}`);
        console.log(response);

        setDetailPersonnel(response.data.data);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };
    getReservationDetail();
  }, [id]);


  if (!detailPersonnel)
    return (
      <div className="detail-student">
        <h5 className="mt-5 text-center">Loading...</h5>
      </div>
    );
  return (
    <>
   {
    detailPersonnel &&  <div className="detail-student">
    
    <div className="back-user">
      <IoChevronBackCircleOutline
        className="back-home"
        onClick={() => {
          navigate(-1);
        }}
      />

      <h5>
        <span className="text-primary">Personnels</span> /{" "}
        <span className="text-primary">Réservations de:  {detailPersonnel.nom}</span>
      </h5>
    </div>
    <div>

    <PersonnelDatatable items={detailPersonnel.service.reservations} />
    </div>
   
  </div>
   }
   </> 
  );
}

function PersonnelDatatable({ items }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} réservations.`;
  
    
  
  
 
    const getDate = (rowData)=>{
      return(
        <span>
          {new Date(rowData.created_at).toLocaleDateString('fr')} 
        </span>
      )
    }
    const getDateAndHour =(rowData)=>{
        return(
            <span>
             {rowData.date} à {rowData.heure}
            </span>
          )
    }
    const getStatut = (rowData)=>{
        return(
            <span style={{
                background:rowData.status=== "En Attente" ? "red":"green",
                color:"white",
                borderRadius:"10px",
                padding:".5em"
            }}>
             {rowData.status}
            </span>
          )
    }
    const detailsClient = (rowData)=>{
        return(
            <span>
             {rowData.user.nom} {rowData.user.prenom}
            </span>
          )
    }
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };
     
      return (
        <div className="conteneur-search">
          
  
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={10}
                filters={filters}
                globalFilterFields={["lastname", "firstname", "phone", "email"]}
                header={header}
              >
                {/* <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Date"
                  body={getDate}
                ></Column> */}
                <Column
                  field="status"
                  style={{ textAlign: "center" }}
                  header="Statut"
                   body={getStatut}
                ></Column>
               
                <Column
                  field="montant"
                  style={{ textAlign: "center" }}
                  header="Montant (Fcfa)"
                ></Column>
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Date et heure du rendez-vous"
                  body={getDateAndHour}
                ></Column>
                
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Client"
                  body={detailsClient}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }

export default DetailPersonnels;

