import React, { useEffect, useState,useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import instance from "../api/api";
import Input from "../features/Input";
import Button from "../features/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Spinner from "react-bootstrap/Spinner";
import { AuthContext } from "../context/AuthContext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


function DetailUser() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailsUser, setDetailsUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const [newPhone, setNewPhone] = useState("");

  const {user} = useContext(AuthContext)
 
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await instance.get("users");
        console.log("responseUser",response);
       
        const data = response.data.data.find((item) => item.id === Number(id));
       console.log(data)
       const element = data ? response.data.data.find((item) => item.id === Number(id)):response.data.data[0].vendeurs.find((item) => item.id === Number(id))
       console.log("element",element)
          setDetailsUser(element)
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };
    getData();
  }, [id]);
  console.log("detailsUser",detailsUser)
  const {role} = detailsUser;
  console.log("role",role)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await instance.put(`users/${id}`, {
        phone: newPhone,
      });
      console.log(response);
      if (!response.data.status) {
        setIsLoading(false);
        MySwal.fire({
          title: "Erreur.",
          icon: "info",
          showConfirmButton: true,
        });
      }
      MySwal.fire({
        title: "Modification effectuée avec succès.",
        icon: "success",
 showConfirmButton: true,
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      MySwal.fire({
        title: error.data.message,
        icon: "success",
 showConfirmButton: true,
      });
    }
  };
  return (
    <div className="detail-student">
      <div className="back-user">
        <IoChevronBackCircleOutline
          className="back-home"
          onClick={() => {
            navigate(-1);
          }}
        />

        <h5>
          <span className="text-primary">Personnel</span> /{" "}
          <span className="text-primary">Détail</span> / {detailsUser.lastname}{" "}
          {detailsUser.firstname}
        </h5>
      </div>
      {detailsUser.lastname ? (
        <div className="conteneur-info-student">
          <form onSubmit={handleSubmit}>
            <div className="conteneur-image-info">
              <div className="profil-image">
                <img src="/person.jpeg" alt="icon" />
              </div>
              <div className="progil-info">
                <h2>
                  {detailsUser.lastname} {detailsUser.firstname}
                </h2>
                <span>{role.label}</span>
                <span className="d-block">{detailsUser.agence ? detailsUser.agence.nom:null}</span>
              </div>
            </div>
            <Input
              active="true"
              label="Nom"
              typeInput="text"
              nameInput="nom"
              valueDefault={detailsUser.firstname}
            />
            <Input
              active={true}
              label="Prénoms"
              typeInput="text"
              nameInput="prenoms"
              valueDefault={detailsUser.lastname}
            />
            <Input
              active={true}
              label="Email"
              typeInput="email"
              nameInput="email"
              valueDefault={detailsUser.email}
            />
            {
              user.role.label !== "Responsable Agence" &&  
             <>
              <div className="input-label-profil">
              <label htmlFor="phone">Téléphone</label>
              <input
                style={{ backgroundColor: "white" }}
                type="text"
                name="phone"
                defaultValue={detailsUser.phone1}
                onChange={(e) => {
                  setNewPhone(e.target.value);
                }}
              />
            </div>
            <Button
              titre="Modifier"
              styleBtn={styleBtn}
              isLoading={isLoading}
            />
             </>
            }
           
          </form>
          {
            user.role.label === "Responsable Agence" && 
            <div className="my-3">
            <h6 className="badge bg-warning">Produits reçus</h6>
           <DatatableDetail detailUser={detailsUser.requetes} />
            </div>
          }
         
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
}


function DatatableDetail({detailUser}) {
 console.log("detailUserDATA",detailUser)
 const [dataFilter,setDataFilter]=useState([]);

 useEffect(()=>{
  setDataFilter(detailUser.filter(item=>item.livraison === "Livrer"))
 },[detailUser])
 const getProducts = (rowData)=>{
  console.log("rowData",rowData.produits)
  return(
    rowData.produits.map(item=>(
      <p key={item.id}>
      {item.libelle}
      </p>
    ))
  )
     
   
 }
 const getQte = (rowData)=>{
  console.log("rowData",rowData.produits)
  return(
    rowData.produits.map(item=>(
      <p key={item.id}>
      {item.pivot.nombre}
      </p>
    ))
  )
 }
 const getDate = (rowData)=>{
return (
  <p>{
    new Date(rowData.created_at).toLocaleDateString('fr')
    }</p>
)
 }
  return (
      <div className="card">
          <DataTable value={dataFilter}
           paginator rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
             tableStyle={{ minWidth: '50rem' }}>
              <Column field="created_at" header="Date de livraison" 
              style={{ textAlign:"center" }} body={getDate}></Column>
              <Column field="produits" header="Produit" 
              style={{ textAlign:"center" }} body={getProducts}></Column>
              <Column field="produits" header="Quantité demandée" 
              style={{ textAlign:"center" }} body={getQte}></Column>
              <Column field="total_restant" header="Nbre restant" 
              style={{ textAlign:"center" }}></Column>
             
          </DataTable>
      </div>
  );
}



export default DetailUser;
