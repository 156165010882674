import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import instance from "../../api/api";
// import Input from "../../features/Input";
// import Button from "../../features/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";
// import Table from "react-bootstrap/Table";
// import { Tabs } from "antd-mobile";
// import {Help} from "../../utils"
import { Dialog } from 'primereact/dialog';

function ReservationsDetail() {
  // const styleBtn = {
  //   padding: ".5em",
  //   borderRadius: "5px",
  //   fontWeight: "bold",
  //   cursor: "pointer",
  //   color: "black",
  //   backgroundColor: "rgb(255 177 47 / 99%)",
  //   marginTop: "1em",
  //   border: "none",
  // };
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const { id } = useParams();
  const [detailReservations, setDetailMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [rejet,setRejet]=useState(false);
  const [visible, setVisible] = useState(false);
  const [commentaire,setCommentaire]=useState("")

  useEffect(() => {
    const getReservationDetail = async () => {
      try {
        const response = await instance.get(`/messages/${id}`);
        console.log(response);

        setDetailMessage(response.data.data);
      } catch (error) {
        console.error("Une erreur s'est produite : ", error);
      }
    };
    getReservationDetail();
  }, [id]);
  const confirmReservation = async()=>{
    setIsLoading(true)
    let dataSend = rejet ? 'Annuler':"Valider";
    try {
      const response = await instance.post(`/reservationsvalider/${id}`,{
        "status": dataSend,
        "commentaire":rejet ? commentaire:""
      });
      console.log(response);
      if(response.data.status){
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
      }
      setIsLoading(false)
    } catch (error) {
      console.error("Une erreur s'est produite : ", error);
      setIsLoading(false)
    }
  }

  if (!detailReservations.created_at)
    return (
      <div className="detail-student">
        <h5 className="mt-5 text-center">Loading...</h5>
      </div>
    );
  return (
    <div className="detail-student">
       <div>
        
            <Dialog header= {rejet ? "Annulation":"Confirmation"} 
            visible={visible} modal={false} 
            style={{ width: '50vw',textAlign:"center" }} 
            onHide={() => setVisible(false)}>
                <p className="m-0">
                 Voulez-vous vraiment {rejet ? 'annuler':'confirmer'} cette réservation ?
                </p> 
               {
                rejet && <div className="text-start">
                 <label htmlFor="comment">Motif</label>
                 <textarea onChange={(e)=>{
                   setCommentaire(e.target.value)
                 }} name="" id="comment" className="w-100" cols="30" rows="10"></textarea>
                 </div>
               }
              <button
              className="btn bg-primary text-light my-2"
              onClick={
                confirmReservation
              }
              disabled={isLoading}
              >
                {
                  isLoading ? 'Loading...':'Confirmer'
                }
                </button>
            </Dialog>
        </div>
      
      <div className="back-user">
        <IoChevronBackCircleOutline
          className="back-home"
          onClick={() => {
            navigate(-1);
          }}
        />

        <h5>
          <span className="text-primary">Message</span> /{" "}
          <span className="text-primary">Détail</span>
        </h5>
      </div>
      <div>
        <p>
         <b>Objet :</b>{" "}
          
            {detailReservations.objet}
         
         
        </p>
        <p>
          <b>Texte :</b>{" "}
          {detailReservations.texte}{" "}
        </p>
        <p>
          <b>Envoyer à :</b>{" "}
          {detailReservations.user.email}{" "}
        </p>

      </div>
      {/* <div className="text-center">
        <button className="btn bg-primary text-light mx-3"
        onClick={() =>{
          setRejet(false)
          setVisible(true)
        } }
        >Accepter</button>
        <button className="btn bg-danger text-light"
        onClick={() => {
          setRejet(true)
          setVisible(true)
        }}
        >Rejeter</button>
      </div> */}
    </div>
  );
}



export default ReservationsDetail;
