import React, { useState } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import instance from "../api/api.js";
import Button from "../features/Button.jsx";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function ForgetPassword() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  const sendEmailForResetPassword = async (e) => {
    setIsLoading(true);
    e.preventDefault()
    console.log(e.target.email.value)
    try {
      const response = await instance.post("password", {
        email: e.target.email.value,
      });
      console.log("response",response)
      if (!response.data.status) {
        setIsLoading(false);
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
      }else{
        MySwal.fire({
          title: response.data.message,
          icon: "success",
   showConfirmButton: true,
        });
      }
      
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      MySwal.fire({
        title: error.data.message,
        icon: "success",
 showConfirmButton: true,
      });
    }
  };
  return (
    <div className="forget-password">
      <form onSubmit={sendEmailForResetPassword}>
        <span
          className="back-home"
          onClick={() => {
            navigate("/");
          }}
        >
          <IoChevronBackCircleOutline />
        </span>

        <h1>Réinitialiser votre mot de passe</h1>
        <input type="email" id="email" placeholder="Enter your email" required />
        <Button titre="Send" styleBtn={styleBtn}  isLoading={isLoading} />
      </form>
    </div>
  );
}

export default ForgetPassword;
