import React, { useState, useRef } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

function InputCreate({ label,
    typeInput,
    nameInput,
    active,
    handleChangePassword,
    getValue,
    dataValue,isRequired,MydefaultValue}) {
    const [togglePasswordContent, setTogglePasswordContent] = useState(false);
    const passwordRef = useRef(null);
    const handlePassword = () => {
      setTogglePasswordContent((prevTogglePassword) => !prevTogglePassword);
      if (!togglePasswordContent) {
        passwordRef.current.type = "text";
      } else {
        passwordRef.current.type = "password";
      }
    };
  return (
    <div className="input-label-profil">
    <label htmlFor={nameInput}>{label}</label>
    <br />
    {!active ? (
      <div className="conteneur-password">
        {togglePasswordContent ? (
          <span className="icon-eye" onClick={handlePassword}>
            <AiOutlineEye />
          </span>
        ) : (
          <span className="icon-hidden-eye" onClick={handlePassword}>
            <AiOutlineEyeInvisible />
          </span>
        )}
        <input
          style={{ backgroundColor: "white" }}
          ref={passwordRef}
          type={typeInput}
          value={dataValue}
          onChange={handleChangePassword}
          required
        />
      </div>
    ) : (
      <input type={typeInput}
       required={isRequired}
       value={dataValue}
  
       onChange={getValue}
       />
    )}
  </div>
  )
}

export default InputCreate
