import React, { useContext, useState } from "react";
import Input from "../features/Input";
import { AuthContext } from "../context/AuthContext";
import axios from "../api/api"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



function Profil() {
  
    const MySwal = withReactContent(Swal)
  const {user} = useContext(AuthContext)
  const [data,setData] = useState({password:null,oldPassword:null,email:null})
  const [isLoading,setIsLoading] = useState(false);
  const handlePassword = (e)=>{
    setData({ ...data, password: e.target.value })
  }
  const handleoldPassword = (e)=>{
    setData({ ...data, oldPassword: e.target.value })
  }

  const sendData = async()=>{
   
    setIsLoading(true)
    const dataForChangePassword = {
      password:data.password,
      oldPassword:data.oldPassword,
      email:user.email
    }
    const dataForChangeOnlyEmail = {
      email:data.email
    }
    const dataSend = data.email ? dataForChangeOnlyEmail:dataForChangePassword;
    const route = data.email ? axios.put(`users/${user.id}`,dataSend):axios.post('modifyPassword',dataSend)
    try{
    const response = await route;
     console.log(response)
    if(!response.data.status){
      MySwal.fire({
        title:response.data.message,
        icon:"info",
        showConfirmButton:true
      })
      setIsLoading(false)
    }else{
      setIsLoading(false)
      MySwal.fire({
        title:response.data.message,
        icon:"success",
        showConfirmButton:false,
        timer:1500
      })
      setData({password:"",oldPassword:"",email:""})
    }
   
    console.log(response)
    }catch(error){
      console.log(error)
      MySwal.fire({
        title: error.response.data.message,
        icon:"info",
        showConfirmButton:true
      })
      setIsLoading(false)
    }
  } 
  
  const sendNewPassword =(e)=>{
    e.preventDefault()
    console.log(data)
      sendData()
  }
  return (
    <div className="profil-setting">
      <div className="conteneur-image-info">
        <div className="profil-image">
          <img src="/person.jpeg" alt="icon" />
        </div>
        <div className="progil-info">
         <h2>{user.prenom} {user.nom}</h2>
         {/* <span>{user.role.libelle}</span> */}
        
        </div>

      </div>
      <form >
        <Input
          label="Nom"
          typeInput="text"
          nameInput="name"
          active={true}
          valueDefault={user.nom}
        />
        <Input
          label="Prénoms"
          typeInput="text"
          nameInput="full-name"
          active={true}
          valueDefault={user.prenom}
        />
        <Input
          label="Email"
          typeInput="text"
          nameInput="email"
          active={true}
          valueDefault={user.email}
        />
        <Input
          label="Ancien mot de passe"
          typeInput="password"
          active={false}
          dataValue={data.oldPassword}
          handleChangePassword={handleoldPassword}
        />
        <Input
          label="Nouveau mot de passe"
          typeInput="password"
          active={false}
          dataValue={data.password}
          handleChangePassword={handlePassword}
        />
        <div>
          <label htmlFor="email">Changer mon adresse email</label>
        </div>
        <input type="email" id="email" style={
 {
  borderRadius: "10px",
  height: "45px",
  border: "2px solid black",
  fontWeight: "bold",
}
        }
        value={data.email} onChange={(e)=>{
          setData({...data,email:e.target.value})
        }} />
        
      <div>
       <div className="text-danger">
        <span>
        *Vous pouvez soit changer le mot de passe ou l'adresse email, mais pas les deux à la fois.
        </span>
       
       </div>
        <button className="btn bg-primary" disabled={isLoading} onClick={sendNewPassword}>
         {isLoading ? 'Loading...':'Modifier'} 
        </button>
      {/* <Button titre="Modifier"  styleBtn={styleBtn} isLoading={isLoading} /> */}
       
      </div>
      </form>
    </div>
  );
}

export default Profil;
