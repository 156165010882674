import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";


function Button({ titre,isLoading,styleBtn}) {

  return (
  
      <button style={styleBtn}>
        {isLoading ? (
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <strong>{titre}</strong>
        )}
      </button>
  );
}

export default Button;
