import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { NavLink} from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import ButtonFeature from "../../features/Button"
// import { BiSolidUserDetail } from "react-icons/bi";
import "primeicons/primeicons.css";

import { AiFillEye } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// import Spinner from "react-bootstrap/Spinner";

function ListePersonnel() {
    const [personnels,setPersonnels] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const getListPersonnel = async () => {
          setIsLoading(true);
          try {
            const response = await instance.get("operateurs");
            console.log("CLIENTS", response.data.data);
            setPersonnels(response.data.data);
            setIsLoading(false);
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        };
        getListPersonnel();
      }, []);
  return (
    <div>
      <h1>Personnel</h1>
      {
        isLoading ?
        <h1>Loading...</h1> : <PersonnelDatatable items={personnels} />
      }
    
    </div>
  )
}
function PersonnelDatatable({ items }) {
    const dt = useRef(null);
  
    const footer = `${items ? items.length : 0} personnels.`;
  
    
  
  
    const detailsInfo = (rowData) => {
      const styleBtn = {
        textDecoration: "none",
        cursor: "pointer",
        fontSize: "1em",
        color: "black",
      };
      return (
        <NavLink to={`/Mesreservations/${rowData.id}`} style={styleBtn}>
          <AiFillEye fontSize="1.5em" />
        </NavLink>
      );
    };
 
    const getLastName = (rowData)=>{
      return(
        <span>
          {rowData.nom}  { rowData.prenom}
        </span>
      )
    }
  
    const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      nom: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
  
      _filters["global"].value = value;
  
      setFilters(_filters);
      setGlobalFilterValue(value);
    };
    const renderHeader = () => {
      const conteneurSearch = {
        padding: "1.3em",
      };
      // const exportExcel = () => {
      //   import("xlsx").then((xlsx) => {
      //     const worksheet = xlsx.utils.json_to_sheet(items);
      //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      //     const excelBuffer = xlsx.write(workbook, {
      //       bookType: "xlsx",
      //       type: "array",
      //     });
  
      //     saveAsExcelFile(excelBuffer, "files");
      //   });
      // };
  
      // const saveAsExcelFile = (buffer, fileName) => {
      //   import("file-saver").then((module) => {
      //     let EXCEL_TYPE =
      //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      //     let EXCEL_EXTENSION = ".xlsx";
      //     const data = new Blob([buffer], {
      //       type: EXCEL_TYPE,
      //     });
  
      //     module.default.saveAs(
      //       data,
      //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      //     );
      //   });
      // };
      return (
        <div className="conteneur-search">
          {/* <div className="btn-upload-file">
            <Button
              type="button"
              label="Excel format"
              icon="pi pi-file-excel"
              style={{
                border: "none",
                color: "white",
                margin: "1em 0",
              }}
              onClick={exportExcel}
              data-pr-tooltip="XLS"
            />
          </div> */}
  
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            style={conteneurSearch}
          />
        </div>
      );
    };
    const header = renderHeader();
  
    return (
      <div>
          <TabView>
            <TabPanel>
              <DataTable
                ref={dt}
                value={items}
                footer={footer}
                tableStyle={{ minWidth: "50rem" }}
                paginator
                rows={10}
                filters={filters}
                globalFilterFields={["lastname", "firstname", "phone", "email"]}
                header={header}
              >
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Nom & prénoms"
                  body={getLastName}
                ></Column>
                <Column
                  field="phone"
                  style={{ textAlign: "center" }}
                  header="Contact"
                ></Column>
               
                <Column
                  field="email"
                  style={{ textAlign: "center" }}
                  header="Email"
                ></Column>
                <Column
                  field="service.libelle"
                  style={{ textAlign: "center" }}
                  header="Service"
                ></Column>
                
                <Column
                  field="id"
                  style={{ textAlign: "center" }}
                  header="Détail"
                  body={detailsInfo}
                ></Column>
              </DataTable>
            </TabPanel>
          </TabView>
      </div>
    );
  }

export default ListePersonnel
