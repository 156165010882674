import React, { useState, useRef } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

function Input({
  label,
  typeInput,
  nameInput,
  valueDefault,
  active,
  handleChangePassword,
  dataValue,
}) {
  const [togglePasswordContent, setTogglePasswordContent] = useState(false);
  const passwordRef = useRef(null);
  const handlePassword = () => {
    setTogglePasswordContent((prevTogglePassword) => !prevTogglePassword);
    if (!togglePasswordContent) {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };
  return (
    <div className="input-label-profil">
      <label htmlFor={nameInput}>{label}</label>
      <br />
      {!active ? (
        <div className="conteneur-password">
          {togglePasswordContent ? (
            <span className="icon-eye" onClick={handlePassword}>
              <AiOutlineEye />
            </span>
          ) : (
            <span className="icon-hidden-eye" onClick={handlePassword}>
              <AiOutlineEyeInvisible />
            </span>
          )}
          <input
            style={{ backgroundColor: "white" }}
            ref={passwordRef}
            type={typeInput}
            value={dataValue}
            onChange={handleChangePassword}
          
          />
        </div>
      ) : (
        <input style={
          {backgroundColor:"rgb(193, 193, 193)"}
        }disabled type={typeInput} defaultValue={valueDefault} />
      )}
    </div>
  );
}

export default Input;
