import React from "react";
import Main from "../Main";
import NavBar from "../NavBar";

function ShowDashBoard() {
  return (
    <div className="conteneur-app">
      <NavBar />
      <Main />
    </div>
  );
}

export default ShowDashBoard;
