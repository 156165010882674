import React, { useEffect, useState } from "react";
import instance from "../../api/api";
// import InputCreate from "../../features/InputCreate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Button from "../../features/Button";
import { MultiSelect } from 'primereact/multiselect';
import { Tabs } from "antd-mobile";
import { Dropdown } from "primereact/dropdown";
const styleBtn = {
  padding: ".5em",
  borderRadius: "5px",
  fontWeight: "bold",
  cursor: "pointer",
  color: "black",
  backgroundColor: "rgb(255 177 47 / 99%)",
  marginTop: "1em",
  border: "none",
};

function CreateMessage() {
  const [listeEvent, setListeEvent] = useState([]);
  const [listePerson, setListePerson] = useState([]);
  const [listeGroupe, setListeGroupe] = useState([]);
  useEffect(() => {
    const getListOfEvent = async () => {
      try {
        const response = await instance.get("evenements");
        const responsePerson = await instance.get("Clients");
        const responseGroupe = await instance.get("groupes");
        console.log("Messages", response);
        console.log("Personnes", responsePerson);
        console.log("Groupes", responseGroupe);
        setListeEvent(response.data.data);
        setListePerson(responsePerson.data.data);
        setListeGroupe(responseGroupe.data.data)
      } catch (error) {
        console.log(error);
      }
    };
    getListOfEvent();
  }, []);

  return (
    <div className="create-user">
      <h1>Envoyer un message</h1>

      <Tabs defaultActiveKey="1">
        <Tabs.Tab title="Personne" key="1">
          <WriteAtPersonn items={listeEvent} persons={listePerson} />
        </Tabs.Tab>
        <Tabs.Tab title="Groupe" key="2">
          <WriteAtGroup items={listeEvent} groups={listeGroupe} />
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}

function WriteAtPersonn({ persons }) {
  const MySwal = withReactContent(Swal);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [data, setData] = useState({event: "",text:""});
  const [isLoading, setIsLoading] = useState(false);


  const registerMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("selectedGroupe",selectedGroupe)

    const tablea = []
    selectedGroupe.forEach(element => {
       
        if(element){
            tablea.push(element.id)
        }
    });
    console.log("tablea",tablea)
   
    try {
      let dataSend ={
        "objet": data.event,
        "texte": data.text,
        "users": tablea
      }
      const response = await instance.post("messages", dataSend);

      console.log(response);
      if (response.data.status === false) {
        let msgError = [];
        for (const property in response.data.message) {
          msgError = [...msgError, response.data.message[property]];
        }
        MySwal.fire({
          title: msgError,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setData({ libelle: "" });
        MySwal.fire({
          title: "Enregistrement reussi.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      let msgError = [];
      for (const property in error.response.data.message) {
        msgError = [...msgError, error.response.data.message[property]];
      }
      MySwal.fire({
        title: msgError,
        icon: "info",
        showConfirmButton: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={registerMessage}>
      <label htmlFor="event">Objet</label>
      <input type="text"
      className="w-100 p-3 rounded mb-5"
      name="" id=""
      onChange={(e)=>{
        setData({...data,event:e.target.value})
    }}
      />
      {/* <select
        name="event"
        id="event"
        defaultValue={data.event}
        onChange={(e)=>{
            setData({...data,event:e.target.value})
        }}
        className="w-100 p-3 rounded mb-5"
      >
        <option value="">Objet</option>
        {items.map((item) => (
          <option value={item.id} key={item.id}>
            {item.libelle}
          </option>
        ))}
      </select> */}
      <label htmlFor="event">Messages</label>
      <textarea className="w-100"
      onChange={(e)=>{
        setData({...data,text:e.target.value})
    }}
      name="" id="" cols="30" rows="10"></textarea>
      <label htmlFor="clients">Séléctionne une personne</label>
      <div id="clients" className="card flex justify-content-center">
        <MultiSelect value={selectedGroupe} 
           onChange={(e) => setSelectedGroupe(e.value)}
            options={persons} 
            optionLabel="email" 
            display="chip" 
            placeholder="Séléctionne une personne" 
            className="w-full md:w-20rem" />
    
      </div>
      <div>
        <Button titre="Créer" styleBtn={styleBtn} isLoading={isLoading} />
      </div>
    </form>
  );
}

function WriteAtGroup({items,groups}) {
    const MySwal = withReactContent(Swal);
    const [selectedGroupe, setSelectedGroupe] = useState(null);
    const [data, setData] = useState({ libelle: "", event: "" ,text:""});
    const [isLoading, setIsLoading] = useState(false);
  
    const registerMessage = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        let dataSend ={
          "objet": data.libelle,
          "texte": data.text,
          "group_id":selectedGroupe.id,
        }
     console.log("dataSend",dataSend)
        const response = await instance.post("messages", dataSend);
  
        console.log(response);
        if (response.data.status === false) {
          let msgError = [];
          for (const property in response.data.message) {
            msgError = [...msgError, response.data.message[property]];
          }
          MySwal.fire({
            title: msgError,
            icon: "info",
            showConfirmButton: true,
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setData({ libelle: "" });
          MySwal.fire({
            title: "Enregistrement reussi.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        console.log(error);
        let msgError = [];
        for (const property in error.response.data.message) {
          msgError = [...msgError, error.response.data.message[property]];
        }
        MySwal.fire({
          title: msgError,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false);
      }
    };
  
    return (
      <form onSubmit={registerMessage}>
        
        <label htmlFor="event">Objet</label>
      <input type="text"
      className="w-100 p-3 rounded mb-5"
      name="" id=""
      onChange={(e)=>{
        setData({...data,libelle:e.target.value})
    }}
      />
        <label htmlFor="event">Messages</label>
        <textarea className="w-100" 
        onChange={(e)=>{
          setData({...data,text:e.target.value})
      }}
        name="" id="" cols="30" rows="10"></textarea>
  
        <label htmlFor="clients">Séléctionne un groupe</label>
        <div id="clients" className="card flex justify-content-center">
        {/* <MultiSelect value={selectedGroupe} 
           onChange={(e) => setSelectedGroupe(e.value)}
            options={groups} 
            optionLabel="libelle" 
            display="chip" 
            placeholder="Séléctionne un groupe" 
            className="w-full md:w-20rem" /> */}
          <Dropdown
            value={selectedGroupe}
            onChange={(e) => setSelectedGroupe(e.value)}
            options={groups}
            optionLabel="libelle"
            placeholder="Séléctionne un groupe"
          />
        </div>
        <div>
          <Button titre="Créer" styleBtn={styleBtn} isLoading={isLoading} />
        </div>
      </form>
    );
  }

export default CreateMessage;
