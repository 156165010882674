import React, { useEffect, useState, useContext } from "react";
import { Card } from "primereact/card";
import instance from "../api/api.js";
import { Chart } from "primereact/chart";
// import { AuthContext } from "../context/AuthContext";
// import { CiFilter } from "react-icons/ci";
// import { Progress, Space } from "antd";
// import AgenceStatistiqueChiffre from "./DataDashboard/AgenceStatistiqueChiffre.jsx"
// import { NavLink } from "react-router-dom";
// import { Dialog } from 'primereact/dialog';

// import DashboardResponsableReseau from './statistique/DashboardResponsableReseau.jsx'
import { Help } from "../utils";

function Accueil() {
  // const dateYear = new Date().toLocaleDateString("fr", {
  //   year: "numeric",
  // });
  const [statistiqueReservation, setStatistiqueReservation] = useState([]);

  // const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  // const [dataClient, setDataClient] = useState([]);
  // const [chartData, setChartData] = useState({});
  // const [chartOptions, setChartOptions] = useState({});
  const [totalGroupe, setTotalGroupe] = useState("");
  // const [months, setMonths] = useState([]);
  const [clients, setClients] = useState("");
  // const [revenus, setRevenus] = useState("");
  // const [data, setData] = useState({
  //   year: "",
  //   month: "",
  //   periode:null,
  // });
  // useEffect(() => {
  //   const getStatistique = async () => {
  //     setIsLoading(true);

  //     try {
  //       let dataSend = {
  //         annee: dateYear,
  //       };
  //       const reponseReservation = await instance.post(
  //         "nombreGlobaleReservation",
  //         dataSend
  //       );
  //       const reponseRevenus = await instance.post(
  //         "nombreGlobaleChiffreAffaire",
  //         dataSend
  //       );
  //       const reponseClient = await instance.post(
  //         "nombreGlobaleClient",
  //         dataSend
  //       );
     
  //       console.log("reponseRevenus", reponseRevenus.data.data);
  //       setRevenus(reponseRevenus.data.data);
  //       if (reponseReservation.data.status && reponseClient.data.status) {
  //         const month = [];
  //         const value = [];
  //         const clients = [];
     

  //         reponseReservation.data.data.forEach((element) => {
  //           month.push(element.mois ? element.mois : element.jour);
  //           value.push(element.valeur);
  //         });

  //         reponseClient.data.data.forEach((element) => {
  //           clients.push(element.valeur);
  //         });

  //         setStatistiqueReservation(value);
  //         setMonths(month);
  //         setDataClient(clients);

  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setIsLoading(true);
  //     }
  //   };
  //   getStatistique();
  // }, [dateYear]);

  // useEffect(() => {
  //   const documentStyle = getComputedStyle(document.documentElement);
  //   const textColor = documentStyle.getPropertyValue("--text-color");
  //   const textColorSecondary = documentStyle.getPropertyValue(
  //     "--text-color-secondary"
  //   );
  //   const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
  //   const data = {
  //     labels: months,
  //     datasets: [
  //       {
  //         label: "Clients",
  //         backgroundColor: documentStyle.getPropertyValue("--blue-500"),
  //         borderColor: documentStyle.getPropertyValue("--blue-500"),
  //         data: dataClient,
  //       },
  //       {
  //         label: "Réservation",
  //         backgroundColor: documentStyle.getPropertyValue("--pink-500"),
  //         borderColor: documentStyle.getPropertyValue("--pink-500"),
  //         data: statistiqueReservation,
  //       },
  //       // {
  //       //   label: "Revenu",
  //       //   backgroundColor: "orange",
  //       //   borderColor: "orange",
  //       //   data: filtreRevenu,
  //       // },
        
        
  //     ],
  //   };
  //   const options = {
  //     maintainAspectRatio: false,
  //     aspectRatio: 0.8,
  //     plugins: {
  //       legend: {
  //         labels: {
  //           fontColor: textColor,
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
          
  //         ticks: {
  //           color: textColorSecondary,
  //           font: {
  //             weight: 500,
  //           },
  //         },
  //         grid: {
  //           display: false,
  //           drawBorder: false,
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: textColorSecondary,
  //         },

  //         grid: {
  //           color: surfaceBorder,
  //           drawBorder: false,
  //         },
  //       },
  //     },
  //   };

  //   setChartData(data);
  //   setChartOptions(options);
  // }, [months, dataClient, statistiqueReservation]);

  useEffect(() => {
    const getListUsers = async () => {
      try {
        const response = await instance.get("Clients")
        const responseReservation = await instance.get("groupes");
        console.log("CLIENTS", response);
        setClients(
          response.data.data.length
        );
        setTotalGroupe(responseReservation.data.data.length);
      } catch (error) {
        console.log(error);
      }
    };
    getListUsers();
  }, []);

  // const filterStatistique = async()=>{
  //   setIsLoading(true);
  //   try {
  //     let dataSend = {
  //       "mois": data.month ? data.month:"",
  //       "annee": data.year ? data.year:"",
  //     }
  //     const reponseReservation = await instance.post(
  //       "nombreGlobaleReservation",
  //       dataSend
  //     );
  //     const reponseClient = await instance.post(
  //       "nombreGlobaleClient",
  //       dataSend
  //     );
      
  //     console.log("reponseReservation.data",reponseReservation.data)
  //     console.log("reponseClient.data",reponseClient.data)
  //     if (reponseReservation.data.status && reponseClient.data.status) {
  //       const month = [];
  //       const value = [];
  //       const clients = [];
  //       // const revenu =[]

  //       reponseReservation.data.data.forEach((element) => {
  //         month.push(element.mois ? element.mois : element.jour);
  //         value.push(element.valeur);
  //       });

  //       reponseClient.data.data.forEach((element) => {
  //         clients.push(element.valeur);
  //       });
  //       // reponseRevenu.data.data.forEach((e)=>{
  //       //   revenu.push(e.valeur)
  //       // })
  //       setStatistiqueReservation(value);
  //       setMonths(month);
  //       setDataClient(clients);
  //       // setFiltreRevenu(revenu)
  //       setIsLoading(false);
       
        
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(true);
  //   }
  // }

  return (
    <div className="conteneur-page">
      <h1>Tableau de bord</h1>
      <div className="d-flex justify-content-center align-items-center">
  
        <div className="card mx-2">
          <Card title="Clients">
            <h1 className="m-0 text-center">{clients}</h1>
          </Card>
        </div>
        <div className="card mx-2">
          <Card title="Groupes">
            <h1 className="m-0 text-center">{totalGroupe}</h1>
          </Card>
        </div>
      </div>
      {/* 
      <div className="d-flex justify-content-center align-items-center">
        <div className="card mx-2">
          <Card title="Revenus">
            <h1 className="m-0 text-center">
              {Help.convertInMoney(revenus)} Fcfa
            </h1>
          </Card>
        </div>
        <div className="card mx-2">
          <Card title="Clients">
            <h1 className="m-0 text-center">{clients.length}</h1>
          </Card>
        </div>
        <div className="card mx-2">
          <Card title="Réservations">
            <h1 className="m-0 text-center">{totalReservation}</h1>
          </Card>
        </div>
      </div>
      <div className="card m-3 text-center">
        <h3 className="text-left">Statistique</h3>
        <div className="d-flex justify-content-center align-items-center">
      
          <select name="periode" id="periode" className="w-50 p-2 mx-2"
          onChange={(e)=>{
            setData({ ...data, periode: e.target.value })
          }}
          >
            <option value="" selected>
              Séléctionne une période
            </option>
            <option value="mois">Mois</option>
            <option value="year">Année</option>
          </select>
         <div className="mx-2">
         {
          data.periode === 'mois' && <input
          type="month"
          value={data.month}
          onChange={(e) => {
            data.year = ""
            setData({ ...data, month: e.target.value })
          }}
        />

        
         }
         {
         data.periode === 'year' && <input
          type="number"
          value={data.year}
          onChange={(e) => {
            data.month = ""
            setData({ ...data, year: e.target.value })
          }}
        />
         }
         </div>
         {
          data.periode && <div className="mx-2">
          <button className="btn bg-dark text-light"
          onClick={filterStatistique}
          >
            
            {isLoading ? 'Loading....':'Filtre'}
          
          </button>
         </div>
         }
        </div>
        <div className="position-relative">
          
        {isLoading ? (
          "Loading..."
        ) : (
          <>
          <span className="position-absolute nombre-statistic">Nombre</span>
          <Chart type="bar"  data={chartData} options={chartOptions} />
          {
            data.month && chartData.length  ? <span 
            className="position-relative year-statistic"
            >Jours</span>:
             <span className="position-relative year-statistic">
              Année</span>
          }
       

          </>
        )}
        </div>
      </div> */}
    </div>
  );
}

export default Accueil;
