import "./App.css";

import Login from "./pages/Login";

function App() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default App;
