import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// import { NavLink } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
// import { Button } from 'primereact/button'
import ButtonFeature from "../../features/Button.jsx"
import "primeicons/primeicons.css";

// import { AiFillEye,AiFillDelete } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import Spinner from 'react-bootstrap/Spinner';
// import DetailUser from "../DetailUser.jsx";
import { Dialog } from 'primereact/dialog';
import { message } from "antd";

function PromotionsList() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);

      const [promotions, setPromotions] = useState([]);
      const [promotionsId, setPromotionsId] = useState("");
      const [showModal, setShowModal] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
        const [showModalEdit,setShowModalEdit]=useState(false)
        const [libelle,setLibelle]=useState("");
        const [date,setDate]=useState("");
        const [message,setMessage]=useState("");
        const action = ({ id,libelle,date,message }) => {
    const deletePromotions = ()=>{
      setPromotionsId(id);
      console.log(promotionsId);
      setShowModal(true);
    }
    const modifierEvent = ()=>{ 
      console.log("idEVNET",id)
      setPromotionsId(id);
      setLibelle(libelle)
      setDate(date)
      setMessage(message)
      setShowModalEdit(true);
    }
    return (
      <div>
<button
      className="btn bg-dark text-light fw-bold mx-2"
        onClick={modifierEvent}
      >
       modifier
      </button>
      <button
      className="btn bg-danger text-light fw-bold"
        onClick={deletePromotions}
      > Supprimer
      </button>
      </div>

    );
  };
    const getListOfPromotions = async () => {
    try {
      const response = await instance.get("evenements");
      console.log(response.data);
      console.log(response.data.data);
      setPromotions(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListOfPromotions();
  }, []);

  const removePromotions = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await instance.delete(`evenements/${promotionsId}`);
      console.log("RESPONSE",response)
      if (response.data.status === false){
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false)
    
      }else{
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setPromotionsId("");
        setShowModal(false);
        setIsLoading(false)
        getListOfPromotions();
      
      }
     
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: error.data.message,
        icon: "info",
        showConfirmButton: true,
      });
    }
  };
  const modifierEvenement = async (e)=>{
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await instance.put(`evenements/${promotionsId}`,{
        libelle:libelle,
        date:date,
        message:message,
      });
      console.log("RESPONSE",response)
      if (response.data.status === false){
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: true,
        });
        setIsLoading(false)
    
      }else{
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setPromotionsId("");
        setShowModalEdit(false);
        setIsLoading(false)
        getListOfPromotions();
      
      }
     
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      MySwal.fire({
        title: error.data.message,
        icon: "info",
        showConfirmButton: true,
      });
    }
  }

  return (
   
       <div className="conteneur-page">
           {showModal ? (
        <div className="conteneur-modal">
          <form onSubmit={removePromotions}>
            <h5 className="m-2">Voulez-vous supprimer ce coupon ?</h5>
               <ButtonFeature titre="Confirmer" styleBtn={styleBtn} isLoading={isLoading} />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setShowModal(false);
              }}
            >
              Annuler
            </button>
          </form>
        </div>
      ) : null} 


<Dialog visible={showModalEdit} 
modal 
header="Modification"
style={{ width: '50rem' }} 
onHide={() =>  setShowModalEdit(false)}>
               <form onSubmit={modifierEvenement}>
               <label htmlFor="event">Titre</label>
            <input type="text" 
            defaultValue={libelle}
            onChange={((e)=>{
              setLibelle(e.target.value)
            })}
             />
             <label htmlFor="event">Messages</label>
            <textarea className="w-100"
      defaultValue={message}
      onChange={((e)=>{
        setMessage(e.target.value)
      })}
      name="" id="" cols="30" rows="10"></textarea>
      <label htmlFor="event">Date</label>
            <input type="date"
            onChange={((e)=>{
              setDate(e.target.value)
            })} 
            defaultValue={date}
             />
               <ButtonFeature 
               titre="Modifier" 
               styleBtn={styleBtn} 
               isLoading={isLoading} 
               />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setShowModalEdit(false);
              }}
            >
              Annuler
            </button>
          </form>
            </Dialog>


      {/* {
        showModalEdit && 
        <div className="conteneur-modal">
          <form onSubmit={modifierEvenement}>
            <input type="text" defaultValue={libelle} />
               <ButtonFeature titre="Modifier" styleBtn={styleBtn} isLoading={isLoading} />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setShowModalEdit(false);
              }}
            >
              Annuler
            </button>
          </form>
        </div>
      } */}
      <h1>Liste des événements</h1>
      <PromotionsDatatable items={promotions}  action={action} />
    </div>
   
  )
}
export function PromotionsDatatable({items,action}) {

  const dt = useRef(null);

  
  const footer = `${items ? items.length : 0} événement.`;



  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tarif: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    duree: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    // const exportExcel = () => {
    //   import("xlsx").then((xlsx) => {
    //     const worksheet = xlsx.utils.json_to_sheet(items);
    //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    //     const excelBuffer = xlsx.write(workbook, {
    //       bookType: "xlsx",
    //       type: "array",
    //     });

    //     saveAsExcelFile(excelBuffer, "files");
    //   });
    // };

    // const saveAsExcelFile = (buffer, fileName) => {
    //   import("file-saver").then((module) => {
    //     let EXCEL_TYPE =
    //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    //     let EXCEL_EXTENSION = ".xlsx";
    //     const data = new Blob([buffer], {
    //       type: EXCEL_TYPE,
    //     });

    //     module.default.saveAs(
    //       data,
    //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    //     );
    //   });
    // };
    return (
      <div className="conteneur-search">
        {/* <div className="btn-upload-file">
          <Button
            type="button"
            label="Excel format"
            icon="pi pi-file-excel"
            style={{
              border: "none",
              color: "white",
              margin: "1em 0",
            }}
            onClick={exportExcel}
            data-pr-tooltip="XLS"
          />
        </div> */}

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();
  

  return (
    <div>
          <TabView>
      <TabPanel>
          <DataTable
            ref={dt}
            value={items}
            footer={footer}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={10}
            filters={filters}
            globalFilterFields={["nom", "contact", "email"]}
            header={header}
          >
            <Column field="libelle" style={{textAlign:"center"}} header="Titre">

            </Column>
            <Column field="id" style={{textAlign:"center"}} 
            header="Action" body={action}>
              
            </Column>
          </DataTable>
        </TabPanel>
        </TabView>
    </div>
  );
}

export default PromotionsList
