import React, {useEffect,useState} from "react";
import instance from "../../api/api";
import InputCreate from "../../features/InputCreate";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Button from "../../features/Button";
import { MultiSelect } from 'primereact/multiselect';


function CreateGroupe() {
    const styleBtn = {
        padding: ".5em",
        borderRadius: "5px",
        fontWeight: "bold",
        cursor: "pointer",
        color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
        marginTop: "1em",
        border: "none",
      };
      const MySwal = withReactContent(Swal);
      const [selectedGroupe, setSelectedGroupe] = useState(null);
      const [data, setData] = useState( {libelle:"",});
      const [isLoading, setIsLoading] = useState(false);
      const [persons, setPersons] = useState([]);
    
      const creategroupes = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const tablea = []
    selectedGroupe.forEach(element => {
       
        if(element){
            tablea.push(element.id)
        }
    });
        try {
         let dataSend = {
          "libelle": data.libelle,
          "users":tablea
        }
          const response = await instance.post("groupes",dataSend);
         
          console.log(response)
          if (response.data.status === false) {
            let msgError = []
            for (const property in response.data.message) {
              msgError = [...msgError,response.data.message[property]]
            }
            MySwal.fire({
              title: msgError,
              icon: "info",
              showConfirmButton: true,
            });
            setIsLoading(false);
          }else{
            setIsLoading(false);
            setData( {libelle:"",});
            MySwal.fire({
              title: "Enregistrement reussi.",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
          let msgError = []
          for (const property in error.response.data.message) {
          
            msgError = [...msgError,error.response.data.message[property]]
          }
          MySwal.fire({
            title: msgError,
            icon: "info",
            showConfirmButton: true,
          });
          setIsLoading(false);
        }
      };
      useEffect(() => {
        const getListOfEvent = async () => {
          try {
            const responsePerson = await instance.get("Clients");
            setPersons(responsePerson.data.data)
          } catch (error) {
            console.log(error);
          }
        };
        getListOfEvent();
      }, []);
      
      return (
        <div className="create-user">
            <h1>Créer un groupe</h1>
          <form onSubmit={creategroupes}>
            <InputCreate
              label="libellé"
              typeInput="text"
              nameInput="text"
              active={true}
              getValue={((e)=>{
                setData({ ...data, libelle: e.target.value });
              })}
              dataValue={data.libelle}
            />
             <label htmlFor="clients">Séléctionne une personne</label>
      <div id="clients" className="card flex justify-content-center">
        <MultiSelect value={selectedGroupe} 
           onChange={(e) => setSelectedGroupe(e.value)}
            options={persons} 
            optionLabel="nom" 
            display="chip" 
            placeholder="Séléctionne une personne" 
            className="w-full md:w-20rem" />
    
      </div>
            <div>
              <Button titre="Créer" styleBtn={styleBtn} isLoading={isLoading} />
            </div>
          </form>
        </div>
      );
}

export default CreateGroupe

