import React, { useState, useContext,useRef } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import {useLocation,NavLink,useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import instance from "../api/api.js";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Button from "../features/Button";


function Login() {
   const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const [togglePasswordContent, setTogglePasswordContent] = useState(false);
  const passwordRef = useRef(null)
  const { login} = useContext(AuthContext);
  const [dataUser, setDataUser] = useState({ email: "", password: "" });
  const [isLoading,setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  
  const MySwal = withReactContent(Swal)
  const handlePassword = () => {
    setTogglePasswordContent((prevTogglePassword) => !prevTogglePassword);
    if(!togglePasswordContent){
      passwordRef.current.type = "text"
    }else{
      passwordRef.current.type = "password"
    }

  };

  const location = useLocation();
  const redirectPath = location.state?.path || "/tableau-bord";
  const handleDataUser = async(e) => {
    e.preventDefault()
    console.log(dataUser)
    setIsLoading(true)
    try{
    
      const response = await instance.post('login',{
       email:dataUser.email,
       password:dataUser.password
      })
      console.log(response)
      if(!response.data.status){
        console.log(response.data.message)
        MySwal.fire({
          title: response.data.message,
          icon:"info",
          showConfirmButton:true
        })
        setIsLoading(false)
      }
      login(response.data.data,response.data.access_token);
      setIsLoading(false)
      
      MySwal.fire({
        title: "Connecté",
        icon:"success",
        timer:1500,
        showConfirmButton:false
      })
      navigate(redirectPath, { replace: true });
     
    }catch(error){
    console.log(error)
    MySwal.fire({
      title: error.response.data.message,
      icon:"info",
      showConfirmButton:true
    })
    setIsLoading(false)
    }
 
   
  };
  return (
    <div className="conteneur-login">
      
      <form onSubmit={handleDataUser}>
      <img src="/message.png" alt="logo" className="w-50 mx-auto p-5" />
        {/* <h1>Se Connecter</h1> */}
        <div>
          <input
            value={dataUser.email}
            type="email"
            name="email"
            placeholder="Email"
            autoComplete="on"
            onChange={(e) =>
              setDataUser({ ...dataUser, email: e.target.value })
            }
            required
          />
        </div>

        <div className="conteneur-password">
          {togglePasswordContent ? (
            <span className="icon-eye" onClick={handlePassword}>
              <AiOutlineEye />
            </span>
          ) : (
            <span className="icon-hidden-eye" onClick={handlePassword}>
              <AiOutlineEyeInvisible />
            </span>
          )}
          <NavLink to="mot-de-passe-oublie" className="password-forget">Mot de passe oublié?</NavLink>
          <input
            type="password"
            onChange={(e) =>
              setDataUser({ ...dataUser, password: e.target.value })
            }
            ref={passwordRef}
            value={dataUser.password}
            placeholder="Password"
            required
          />
        </div>
        <Button titre="Se connecter" styleBtn={styleBtn}  isLoading={isLoading} />

        {/* <button style={styleBtn} type="submit">
           {
            isLoading ? <ProgressSpinner
           style={{width: '20px', height: '20px'}} 
          strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />:
          <strong>Login</strong>}
        </button> */}
      </form>
    </div>
  );
}

export default Login;
