import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { NavLink } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button'
import ButtonFeature from "../../features/Button.jsx"
import "primeicons/primeicons.css";

import { AiFillEye,AiFillDelete } from "react-icons/ai";

import { TabView, TabPanel } from "primereact/tabview";
import instance from "../../api/api.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Spinner from 'react-bootstrap/Spinner';
// import DetailUser from "../DetailUser.jsx";

function CouponsList() {
  const styleBtn = {
    padding: ".5em",
    borderRadius: "5px",
    fontWeight: "bold",
    cursor: "pointer",
    color: "black",
    backgroundColor: "rgb(255 177 47 / 99%)",
    marginTop: "1em",
    border: "none",
  };
  const MySwal = withReactContent(Swal);

      const [coupons, setCoupons] = useState([]);
      const [couponsId, setCouponsId] = useState("");
      const [status, setStatus] = useState("");
      const [showModal, setShowModal] = useState(false);
        const [isLoading, setIsLoading] = useState(false);
     
        const action = ({ id,status }) => {
    const modifyCoupon = ()=>{
      setCouponsId(id);
      console.log(couponsId);
      setStatus(status)
      setShowModal(true);
    }
    return (
      status === "Actif" ?
      <button
      className="btn bg-dark text-light fw-bold"
        onClick={modifyCoupon}
      >
       Désactiver
      </button>:<span className="badge bg-danger">x</span>
    );
  };
    const getListOfCoupons = async () => {
    try {
      const response = await instance.get("coupons");
      console.log(response.data);
      console.log(response.data.data);
      setCoupons(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getListOfCoupons();
  }, []);

  const modifyCoupons = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await instance.put(`coupons/${couponsId}`,{
        status: status === "Actif" ? "Expiré":"",
      });
      console.log("RESPONSE",response)
      if (response.data.status === false){
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false)
    
      }else{
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setCouponsId("");
        setShowModal(false);
        setIsLoading(false)
        // getListOfCoupons();
      
      }
     
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: error.data.message,
        icon: "info",
        showConfirmButton: true,
      });
    }
  };

  return (
   
       <div className="conteneur-page">
           {showModal ? (
        <div className="conteneur-modal">
          <form onSubmit={modifyCoupons}>
            <h5 className="m-2">Voulez-vous désactiver ce coupon ?</h5>
               <ButtonFeature titre="Confirmer" styleBtn={styleBtn} isLoading={isLoading} />
            <button
            className="btn-lg bg-danger mx-2 border-0 rounded-3 p-2 text-light fw-bold"
              onClick={(e) => {
                e.preventDefault()
                setShowModal(false);
              }}
            >
              Annuler
            </button>
          </form>
        </div>
      ) : null} 
      <h1>Liste des coupons</h1>
      <CouponsDatatable items={coupons}  action={action} />
    </div>
   
  )
}
export function CouponsDatatable({items,action}) {

  const dt = useRef(null);

  
  const footer = `${items ? items.length : 0} couponss.`;

  const detailsInfo = (rowData) => {
    const styleBtn = {
      textDecoration: "none",
      cursor: "pointer",
      fontSize: "1em",
      color: "black",
    };
    return (
      <NavLink to={`/coupons/${rowData.id}`} style={styleBtn}>
        <AiFillEye fontSize="1.5em" />
      </NavLink>
    );
  };
  // const showClient =(rowData)=>{
  //   return (
      
  //       rowData.user ?
  //        <p>{rowData.user.nom} {rowData.user.prenom}</p>:
  //        <p>Coupon gratuit</p> 
      
      
  //   );
  // }

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    libelle: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tarif: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    duree: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    const conteneurSearch = {
      padding: "1.3em",
    };
    // const exportExcel = () => {
    //   import("xlsx").then((xlsx) => {
    //     const worksheet = xlsx.utils.json_to_sheet(items);
    //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    //     const excelBuffer = xlsx.write(workbook, {
    //       bookType: "xlsx",
    //       type: "array",
    //     });

    //     saveAsExcelFile(excelBuffer, "files");
    //   });
    // };

    // const saveAsExcelFile = (buffer, fileName) => {
    //   import("file-saver").then((module) => {
    //     let EXCEL_TYPE =
    //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    //     let EXCEL_EXTENSION = ".xlsx";
    //     const data = new Blob([buffer], {
    //       type: EXCEL_TYPE,
    //     });

    //     module.default.saveAs(
    //       data,
    //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    //     );
    //   });
    // };
    return (
      <div className="conteneur-search">
       

        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Keyword Search"
          style={conteneurSearch}
        />
      </div>
    );
  };
  const header = renderHeader();
  

  return (
    <div>
          <TabView>
      <TabPanel>
          <DataTable
            ref={dt}
            value={items}
            footer={footer}
            tableStyle={{ minWidth: "50rem" }}
            paginator
            rows={10}
            filters={filters}
            globalFilterFields={["nom", "contact", "email"]}
            header={header}
          >
            <Column field="code" style={{textAlign:"center"}} header="Code"></Column>
            {/* <Column field="user" style={{textAlign:"center"}}
             body={showClient} header="Client"></Column> */}
            <Column field="pourcentage" style={{textAlign:"center"}} header="Pourcentage (%)"></Column>
            <Column field="status" style={{textAlign:"center"}} header="Status"></Column>
            <Column field="id" style={{textAlign:"center"}} header="Détail" body={detailsInfo}></Column>
            <Column field="id" style={{textAlign:"center"}}
             header="Action" body={action}></Column>
          </DataTable>
        </TabPanel>
        </TabView>
    </div>
  );
}

export default CouponsList
