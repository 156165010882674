import React, { useContext, useState } from "react";
import { ReducerNavbarContext } from "../context/ResizeContext";
import { NavLink, useNavigate } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlinePieChart,
  AiOutlinePlus,
  AiOutlineLine,
} from "react-icons/ai";
import { CiSaveDown2, CiSquareQuestion } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { FiCreditCard } from "react-icons/fi";

import { BiLogOut } from "react-icons/bi";

import {
  BsFillPersonVcardFill,
  BsListColumnsReverse,
  BsSave,
} from "react-icons/bs";
// import { ImStatsDots } from "react-icons/im";

import { AuthContext } from "../context/AuthContext";
import { MyThemeContext } from "../context/ThemeContext";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Collapse from "react-bootstrap/Collapse";
import instance from "../api/api";
// import { AlarmContext } from "../context/AlarmContext.jsx";
const lien = "#";

function NavBar() {
  // const { prospectsLength } = useContext(AlarmContext);
  const MySwal = withReactContent(Swal);
  const { theme } = useContext(MyThemeContext);
  const { logOut, user } = useContext(AuthContext);
  const [openConfigEvent, setOpenConfigEvent] = useState(false);
const [messagerie,setMessagerie] = useState(false);
  const [showPromotion, setShowPromotion] = useState(false);

  const [openConfigClient, setOpenConfigClient] = useState(false);
  const [showClient, setShowClient] = useState(false);

  const [openConfigGroupe, setOpenConfigGroupe] = useState(false);
  const [condition, setCondition] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { toggleNavBar, resizeNavBar } = useContext(ReducerNavbarContext);
  const navigate = useNavigate();
  const styleNavBar = {
    marginLeft: toggleNavBar ? "-20em" : "0",
    backgroundColor: theme ? "#1c1c1c" : "white",
  };

  const logout = async () => {
    setIsLoading(true);
    try {
      const response = await instance.post("logout");
      console.log(response);
      if (!response.data.status) {
        console.log(response.data.message);
        MySwal.fire({
          title: response.data.message,
          icon: "info",
          showConfirmButton: true,
        });
        setIsLoading(false);
      } else {
        logOut();
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          showConfirmButton: true,
        });
        setIsLoading(false);
        navigate("/");
      }
    } catch (error) {
      console.log(error);
      MySwal.fire({
        title: error.response.data.message,
        icon: "info",
        showConfirmButton: true,
      });
      setIsLoading(false);
    }
  };
  const resize = (e) => {
    e.preventDefault();
    resizeNavBar();
  };

  const togglePromotion = () => {
    setOpenConfigEvent((openConfigClient) => !openConfigClient);
    setShowPromotion((agence) => !agence);
  };

  const toggleClients = () => {
    setOpenConfigClient((agence) => !agence);
    setShowClient((agence) => !agence);
  };

  const toggleGroupe = () => {
    setOpenConfigGroupe((Request) => !Request);
    setShowCoupon((Request) => !Request);
  };
 

  return (
    <div className="conteneur-navbar" style={styleNavBar}>
      <div className="conteneur-logo">
        {/* <div className="logo-entreprise"></div> */}
        <div>{/* <img src="/IGP.jpeg" alt="logo" style={styleImg} /> */}</div>
      </div>
      <ul className="liste-menu-interface">
        <li>
          <NavLink to="/tableau-bord">
            <AiOutlinePieChart /> Tableau de bord
          </NavLink>
        </li>
        <li>
          <NavLink to="profil">
            <CgProfile /> Profil
          </NavLink>
        </li>
       

        {/* <li>
          <NavLink to="/clients">
            <IoPersonOutline /> Clients
          </NavLink>
        </li> */}

      

        <li>
          <a
            href={lien}
            onClick={toggleGroupe}
            aria-controls="example-collapse-text"
            aria-expanded={openConfigGroupe}
          >
            <CiSquareQuestion />
            Groupe
            {showCoupon ? (
              <span className="line-plus">
                <AiOutlineLine />
              </span>
            ) : (
              <span className="line-plus">
                <AiOutlinePlus />
              </span>
            )}
          </a>
        </li>
        <Collapse in={openConfigGroupe}>
          <ul id="example-collapse-text">
            <li>
              <NavLink to="/groupe">
                <BsListColumnsReverse /> Liste
              </NavLink>
            </li>
            <li>
              <NavLink to="/creer-groupe">
                <BsSave /> Création
              </NavLink>
            </li>
          </ul>
        </Collapse>

        <li>
          <a
            href={lien}
            onClick={toggleClients}
            aria-controls="example-collapse-text"
            aria-expanded={showClient}
          >
            <IoPersonOutline />  Clients
            {showClient ? (
              <span className="line-plus">
                <AiOutlineLine />
              </span>
            ) : (
              <span className="line-plus">
                <AiOutlinePlus />
              </span>
            )}
          </a>
        </li>

         <Collapse in={openConfigClient}>
          <ul id="example-collapse-text">
            <li>
              <NavLink to="/clients">
                <BsListColumnsReverse /> Liste
              </NavLink>
            </li>
            <li>
              <NavLink to="creer-client">
                <BsSave /> Enregistrer
              </NavLink>
            </li>
          </ul>
        </Collapse>

        <li className="position-relative">
          <a
            href={lien}
            onClick={togglePromotion}
            aria-controls="example-collapse-text"
            aria-expanded={openConfigEvent}
          >
            <IoPersonOutline /> Evenements
            {showPromotion ? (
              <span className="line-plus">
                <AiOutlineLine />
              </span>
            ) : (
              <span className="line-plus">
                <AiOutlinePlus />
              </span>
            )}
          </a>
        </li> 
        <Collapse in={openConfigEvent}>
          <ul id="example-collapse-text">
            <li>
              <NavLink to="/creer-event">
                <BsFillPersonVcardFill /> Enregistrer
              </NavLink>
            </li>
            <li className="position-relative">
              <NavLink to="/event">
                <IoPersonOutline /> Liste
              </NavLink>
            </li>
          </ul>
        </Collapse>


        <li className="position-relative">
          <a
            href={lien}
            onClick={()=>{
              setMessagerie(message=>!message)
            }}
            aria-controls="example-collapse-text"
            aria-expanded={messagerie}
          >
            <IoPersonOutline /> Messagerie
            {messagerie ? (
              <span className="line-plus">
                <AiOutlineLine />
              </span>
            ) : (
              <span className="line-plus">
                <AiOutlinePlus />
              </span>
            )}
          </a>
        </li> 
        <Collapse in={messagerie}>
          <ul id="example-collapse-text">
            <li>
              <NavLink to="/creer-message">
                <BsFillPersonVcardFill /> Enregistrer
              </NavLink>
            </li>
            <li className="position-relative">
              <NavLink to="/messages">
                <IoPersonOutline /> Liste
              </NavLink>
            </li>
          </ul>
        </Collapse>
      </ul>

      <button onClick={resize} className="btn-reducer">
        <FiCreditCard size="2em" color={theme ? "white" : "#1c1c1c"} />
      </button>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <span className="spinner-border">
            <span className="visually-hidden">Loading...</span>
          </span>
        </div>
      ) : (
        <button className="btn-deconnex fw-bold" onClick={logout}>
          <BiLogOut size="1.5em" />
          Déconnexion
        </button>
      )}
    </div>
  );
}

export default NavBar;
